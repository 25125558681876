import { useState } from "react";
import { getCorporateInfo } from "../../../api/corporate/corporate";
import { toast } from "react-toastify";
import ResponseStatus from "../../../util/enums";
import useAuth from "../../../hooks/useAuth";

const useGetUserCorporate = () => {
  const { user, userAdminToken } = useAuth();
  const [userCorporateInfo, setUserCorporateInfo] = useState(null);
  const getUserCorporateInfo = async (userInfo) => {
    const userCorporate = await getCorporateInfo(userAdminToken, userInfo.UserId);
    if (userCorporate.data.ResponseStatus === ResponseStatus.Success) {
      setUserCorporateInfo(userCorporate.data.ResponseData);
    } else if (userCorporate.data.ResponseStatus === ResponseStatus.Error) {
      toast.error(userCorporate.data.ResponseMessage);
      setUserCorporateInfo(userCorporate.data.ResponseData);
    }else if (userCorporate.data.ResponseStatus === ResponseStatus.DataIsNull) {
      toast.error(userCorporate.data.ResponseMessage);
      setUserCorporateInfo(userCorporate.data.ResponseData);
    }
  };
  return { userCorporateInfo, getUserCorporateInfo,setUserCorporateInfo };
};

export default useGetUserCorporate;
