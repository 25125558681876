import { useState } from "react";
import { getUserCorporateSchool } from "../../../api/corporate/corporate";
import { toast } from "react-toastify";
import ResponseStatus from "../../../util/enums";
import useAuth from "../../../hooks/useAuth";

const useGetUserSchoolInfo = () => {
    const { user, userAdminToken } = useAuth()
    const [userSchoolInfo, setUserSchoolInfo] = useState(null);
  const getSchool = async (corporateId) => {
      const userSchool = await getUserCorporateSchool(corporateId,userAdminToken);
      if (userSchool.status == 200) {

      if (userSchool.data.ResponseStatus === ResponseStatus.Success) {
        setUserSchoolInfo(userSchool.data.ResponseData);
      }else if(userSchool.data.ResponseStatus === ResponseStatus.Error){
        toast.error(userSchool.data.ResponseMessage)
        setUserSchoolInfo(userSchool.data.ResponseData)
      }else{
        toast.error(userSchool.data.ResponseMessage)
      }
    }else{
      toast.error("Bir Hata Oluştu")
    }
    } 
    return { userSchoolInfo,getSchool,setUserSchoolInfo };
};


export default useGetUserSchoolInfo;
