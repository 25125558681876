import { toast } from "react-toastify";
import ResponseStatus from "../../../util/enums";
import useAuth from "../../../hooks/useAuth";
import { roleUpdate } from "../../../api/corporate/corporate";
import UserRoles from "../../../enums/UserRoles";
import useHandleOnChangeEmail from "./useHandleOnChangeEmail";

const useChangeRole = ({ getUserInfo }) => {
  const { user,userAdminToken } = useAuth();
  const {  email } = useHandleOnChangeEmail();
  const changeUserRole = async (userInfo,userMail) => {
    if (!userInfo || !userInfo.UserId) {
      toast.error("Invalid user information.");
      return;
    }

    const reqData = userInfo.UserId;



    try {
      const res = await roleUpdate(reqData, userAdminToken);
      if (res.data.ResponseStatus === ResponseStatus.Success) {
        toast.success("Kullanıcı Rolu Başarıyla Değiştirildi");
        getUserInfo(userMail)
      } else {
        toast.error("Bir Hata Oluştu");
      }
    } catch (error) {
      toast.error("Bir Hata Oluştu");
    }
  };

  return { changeUserRole };
};

export default useChangeRole;
