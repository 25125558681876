import React, { useState } from "react";
import "./index.css"; // Make sure you have this CSS file to style the switch

const SwitchToggleComponent = ({
  options = [], // Array of options to switch between
  initialValue = "", // Initial value of the switch
  onValueChange, // Callback function to handle value change
}) => {
  const [selectedValue, setSelectedValue] = useState(initialValue);

  const handleSwitchToggle = (value) => {
    setSelectedValue(value);
    if (onValueChange) {
      onValueChange(value); // Trigger the callback with the new value
    }
  };

  return (
    <div className="switch-container">
      {options.map(({ label, value }) => (
        <button
          key={value}
          onClick={() => handleSwitchToggle(value)}
          className={`switch-button ${selectedValue === value ? "active" : ""}`}
        >
          {label}
        </button>
      ))}
    </div>
  );
};

export default SwitchToggleComponent;
