import React from 'react'

const Buttons = ({onClick,name}) => {
    const styles = {
        button: {
          padding: 12,
        },
      };
  return (
    <button onClick={onClick} style={styles.button}>
        {name}
      </button>
  )
}

export default Buttons