import React from "react";

const UserInfoTable = ({ userInfo, nullMessage }) => {
  if (!userInfo || userInfo.length === 0) {
    return <p>{nullMessage}</p>;
  }
  console.log("<<<userInfoTable>>>",userInfo);
  // Determine headers based on the first object in the array or the single object
  const headers = Array.isArray(userInfo)
    ? Object.keys(userInfo[0]) // If array, use the first object's keys
    : Object.keys(userInfo);

  // Render data rows depending on whether userInfo is an array or a single object
  const dataRows = Array.isArray(userInfo) ? userInfo : [userInfo];

  return (
    <div>
      <table>
        <thead>
          <tr>
            {headers.map((header) => (
              <th key={header}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {dataRows.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {headers.map((header) => {
      const cellValue = row[header];
      console.log("Cell Value:", cellValue); // Debug here
      return (
        <td key={header}>
          {typeof cellValue === "boolean"
            ? cellValue
              ? "True"
              : "False"
            : cellValue}
        </td>
      );
    })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default UserInfoTable;
