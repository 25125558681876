import { ApiUrl, ApiUrlAdmin } from './common/Config';
import { post, put,get, getAdmin } from './common/Helper';

const userUrl = ApiUrl + 'user/';
const authUrl = ApiUrl + 'auth/';
const authUrlP = ApiUrlAdmin + 'auth/'

export const register = async (user) => {
    let url = userUrl + 'register';
    return await post(url, user, null);
};

export const login = async(user) => {
    let url = authUrl + 'token';
    return await post(url, user, null);
};
export const loginP = async(user) => {
    let url = authUrlP + 'token';
    return await post(url, user, null);
};

export const getAllUsers = async(paging, token) => {
    let url = userUrl + 'getallwithdetail';
    return await post(url, paging, token);
};

export const createUserByAdmin = async(newUser, token) => {
    let url = userUrl + 'create';
    return await post(url, newUser, token);
};

export const deleteUserByAdmin = async(user, token) => {
    let url = userUrl + 'delete';
    return await put(url, user, token);
};
export const getMemberByEmail = async(data,loginType, token,isAdmin) => {
    let url = ApiUrlAdmin + 'credit/premiuminfo?userName='+data+'&loginType='+loginType
    return await getAdmin(url,token,isAdmin);
};
export const updateCreditStatus = async(id, token,isAdmin) => {
    let url = ApiUrlAdmin + 'credit/updatestatus?creditId='+id;
    return await getAdmin(url,token,isAdmin);
};
export const clearUserCache = async(id, token,isAdmin) => {
    let url = ApiUrlAdmin + 'media/clear-user-credit?userId='+id;
    return await getAdmin(url,token,isAdmin);
};
export const addNewCredit = async(data, token,isAdmin) => {
    let url = ApiUrlAdmin + 'credit/addcreditfromadminpanel';
    return await post(url,data,token,isAdmin);
};
export const addNewCreditBulk = async(data, token,isAdmin) => {
    let url = ApiUrlAdmin + 'credit/addbulksalecredit';
    return await post(url,data,token,isAdmin);
};
export const getAllUserEmail = async(data,loginType,token) =>{
    let url = ApiUrlAdmin + `account/getallusersemail?userName=${data}&loginType=${loginType}`;
    return await get(url,token)
}
export const resetUserPassword = async(data,token) =>{
    let url = ApiUrlAdmin + `account/passwordreset?userId=${data}`;
    return await get(url,token)
}
export const creditAccordingToDate = async(data, token,isAdmin) => {
    let url = ApiUrlAdmin + 'credit/getcreditaccordingtoreceiptdate';
    return await post(url,data,token);
};