import React, { useState } from "react";

//#region Material Components
import { withStyles } from "@material-ui/styles";
import MuiDialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
//#endregion

//#region Internal
import ApproveButton from "./ApproveButton";

import { Primary } from "../../assets/Colors";
import { getReportsDetails, getImageUrlR, cropPage } from "../../api/FeedBacks";
import { useEffect } from "react";
import { Modal } from "@material-ui/core";
import {
  deleteQuestion,
  deleteVideo,
  deleteQuestionAdmin,
  deleteVideoAdmin,
  getEditorialSystemId,
} from "../../api/BookSectionCrop";
import { toast } from "react-toastify";
import EditorialSystemId from "../../enums/EditorialSystemId";

//#endregion
const Dialog = withStyles({
  paperWidthSm: {
    maxWidth: 920,
    minWidth: 300,
    minHeight: 220,
  },
})(MuiDialog);
const DialogContent = withStyles({
  root: {
    paddingTop: 0,
    display: "flex",
    flexDirection: "column",
    width:"50%",
  },
})(MuiDialogContent);

const DialogTitle = withStyles({
  root: {
    marginLeft: 20,
  },
})(MuiDialogTitle);

let option = "";
function ReportDialog(props) {
  const [details, setDetails] = useState([]);
  const [cropIdDetails, setCropIdDetails] = useState([]);
  const [showConfirm, setShowConfirm] = useState(false);
  const [imageUrl, setImageUrl] = useState({
    mainImage: null,
    imageParts: [],
  });
  const [editorialSystemIdInfo, setEditorialSystemIdInfo] = useState(null);
  const [displayDeleteQuestionModal, setDisplayDeleteQuestionModal] =
    useState(false);
  const [displayDeleteVideoModal, setDisplayDeleteVideoModal] = useState(false);
  const row = props.editRow.content;
  const cropId = props.selectedCropId;
  const correct = row
    ? row[6].content
    : cropIdDetails
    ? cropIdDetails.AnswerOption
    : "";
  const pJWT = sessionStorage.getItem("jwtP");
  const getDetails = async () => {
    let res = await getReportsDetails(row[3].content, props.user.Token);
    if (res.status === 200) {
      setDetails(res.data);
    }
  };
  const getDetailsNEW = async () => {
    let res = await getReportsDetails(cropId, props.user.Token);
    if (res.status === 200) {
      setDetails(res.data);
    }
  };
  const deleteVideoReq = async (data) => {
    const resDev = await deleteVideo(data, props.user.Token);
    const resProd = await deleteVideoAdmin(data, pJWT);
    if (resDev.status == 200) {
      if (resDev) {
        toast.success("Video Deleted Successfully on Dev");
      } else {
        toast.error("Video could not found on Dev");
      }
    } else toast.error("Bir hata oluştu lütfen tekrar deneyiniz");
    if (resProd.status == 200) {
      if (resProd) {
        toast.success("Video Deleted Successfully on Prod");
      } else {
        toast.error("Video could not found on Prod");
      }
    } else if (resProd.status == 401) {
      toast.error("Lütfen Tekrar Giriş Yapınız");
    } else toast.error("Bir hata oluştu lütfen tekrar deneyiniz");
  };
  const deleteQuestionReq = async (data) => {
    const resDev = await deleteQuestion(data, props.user.Token);
    const resProd = await deleteQuestionAdmin(data, pJWT);
    if (resDev.status == 200) {
      if (resDev) {
        toast.success("Question Deleted Successfully on Dev");
      } else {
        toast.error("Question could not found on Dev");
      }
    } else toast.error("Bir hata oluştu lütfen tekrar deneyiniz");
    if (resProd.status == 200) {
      if (resProd) {
        toast.success("Question Deleted Successfully on Prod");
      } else {
        toast.error("Question could not found on Prod");
      }
    } else if (resProd.status == 401) {
      toast.error("Lütfen Tekrar Giriş Yapınız");
    } else toast.error("Bir hata oluştu lütfen tekrar deneyiniz");
  };
  const getCropPage = async () => {
    let res = await cropPage(Number(cropId), props.user.Token);
    if (res.status === 200) {
      setCropIdDetails([]);
      setCropIdDetails(res.data);
    } else {
      props.modalControl(false);
    }
  };
  useEffect(() => {
    if (cropId) {
      getCropPage();
      getDetailsNEW();
    }
  }, [cropId]);
  const getImageUrl = async (share) => {
    const param = {
      BookSectionId: row[4].content,
      QuestionNumber: row[5].content,
    };
    let res = await getImageUrlR(param, props.user.Token);
    if (res.status === 200) {
      let url = "https://product.pakodemy.com/share/index.htm?q=";
      if (share) {
        let q = res.data.ImageUrl.substring(
          res.data.ImageUrl.indexOf("crops") + 6
        );
        const part = res.data.ImagePartUrls[0];
        if (part) {
          q += `__${part.substring(part.indexOf("crops") + 6)}`;
        }
        url = url + q;
      } else {
        url = res.data.ImageUrl;
      }
      const newWindow = window.open(url, "_blank", "noopener,noreferrer");
      if (newWindow) newWindow.opener = null;
    }
  };
  const getImageUrlNEW = async (share) => {
    const param = {
      BookSectionId: cropIdDetails.BookSectionId,
      QuestionNumber: cropIdDetails.QuestionNumber,
    };
    let res = await getImageUrlR(param, props.user.Token);
    if (res.status === 200) {
      let url = "https://product.pakodemy.com/share/index.htm?q=";
      if (share) {
        let q = res.data.ImageUrl.substring(
          res.data.ImageUrl.indexOf("crops") + 6
        );
        const part = res.data.ImagePartUrls[0];
        if (part) {
          q += `__${part.substring(part.indexOf("crops") + 6)}`;
        }
        url = url + q;
      } else {
        url = res.data.ImageUrl;
      }
      const newWindow = window.open(url, "_blank", "noopener,noreferrer");
      if (newWindow) newWindow.opener = null;
    }
  };

  // const showImageOnModal = async() => {
  //   if (cropId) {
  //     const param = {
  //       BookSectionId: cropIdDetails.BookSectionId,
  //       QuestionNumber: cropIdDetails.QuestionNumber,
  //     };
  //     let res = await getImageUrlR(param, props.user.Token);
  //     if (res.status === 200) {
  //       console.log("cropIdres.data.ImageUrl",res.data.ImageUrl);
  //     return res.data.ImageUrl
  //     }
  //   } else if (row) {
  //     const param = {
  //       BookSectionId: row[4].content,
  //       QuestionNumber: row[5].content,
  //     };
  //     let res = await getImageUrlR(param, props.user.Token);
  //     if (res.status === 200) {
  //       console.log("rowres.data.ImageUrl",res.data.ImageUrl);
  //       return res.data.ImageUrl
  //       }
  //   } else {
  //     return;
  //   }
  // }
  const fetchImageUrl = async () => {
    let param;

    if (cropId) {
      param = {
        BookSectionId: cropIdDetails.BookSectionId,
        QuestionNumber: cropIdDetails.QuestionNumber,
      };
    } else if (row) {
      param = {
        BookSectionId: row[4].content,
      QuestionNumber: row[5].content,
      };
    } else {
      return;
    }

    try {
      const res = await getImageUrlR(param, props.user.Token);
      if (res.status === 200) {
        console.log('Fetched Image URL:', res.data.ImageUrl);
        const { ImageUrl, ImagePartUrls } = res.data;
        setImageUrl({
          mainImage: ImageUrl,
          imageParts: ImagePartUrls,
        });
      }
    } catch (error) {
      console.error('Error fetching image URL:', error);
    }
  };
  const fetchEditorialSystemId = async() => {
      let param;
  
      if (cropId) {
        param = {
          BookSectionId: cropIdDetails.BookSectionId,
         
        };
      } else if (row) {
        param = {
          BookSectionId: row[4].content,
       
        };
      } else {
        return;
      }
  
      try {
        const res = await getEditorialSystemId(param.BookSectionId, props.user.Token);
        if (res.status === 200) {
          console.log('Fetched Image URL:', res.data.ImageUrl);
          setEditorialSystemIdInfo(res.data.ResponseData);
        }
      } catch (error) {
        console.error('Error fetching image URL:', error);
      }
    
  }
  useEffect(() => {
    fetchImageUrl();
    fetchEditorialSystemId();
  }, [cropId, cropIdDetails, row, props.user.Token]);


  useEffect(() => {
    if (row) getDetails();
  }, [row]);

  const chAnswer = (opt) => {
    return correct === opt
      ? "*| " + opt + " |*"
      : opt === option
      ? opt + "-"
      : opt;
  };
  const onClickAnswerChange = (r, opt) => {
    option = opt;
    setShowConfirm(true);
  };
  const openVideo = () => {
    const url = row[7].content;
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };
  const openVideoNEW = () => {
    const url = cropIdDetails.AnswerVideoPath;
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };
  return (
    <Dialog id={props.id} open={props.isOpen} onClose={props.onClose}>
      {displayDeleteQuestionModal && (
        <Dialog
          open={displayDeleteQuestionModal}
          onClose={() => setDisplayDeleteQuestionModal(false)}
        >
          <div style={styles.deleteModalStyle}>
            <p>Artık bu soruyu kullanıcılar göremeyecek.</p>
            <p>Silmek istediğine emin misin?</p>
            <div style={styles.deletebuttonStyle}>
              <button
                onClick={() => {
                  deleteQuestionReq(cropId || row[4].content);
                  setDisplayDeleteQuestionModal(false);
                }}
                style={styles.deleteConfirm}
              >
                Sil
              </button>
              <button
                onClick={() => setDisplayDeleteQuestionModal(false)}
                style={styles.deleteConfirmClose}
              >
                Vazgeç
              </button>
            </div>
          </div>
        </Dialog>
      )}
      {displayDeleteVideoModal && (
        <Dialog
          open={displayDeleteVideoModal}
          onClose={() => setDisplayDeleteVideoModal(false)}
        >
          <div style={styles.deleteModalStyle}>
            <p>Artık bu sorunun videosunu kullanıcılar göremeyecek.</p>
            <p>Silmek istediğine emin misin?</p>
            <div style={styles.deletebuttonStyle}>
              <button
                onClick={() => {
                  deleteVideoReq(cropId || row[4].content);
                  setDisplayDeleteVideoModal(false);
                }}
                style={styles.deleteConfirm}
              >
                Sil
              </button>
              <button
                onClick={() => setDisplayDeleteVideoModal(false)}
                style={styles.deleteConfirmClose}
              >
                Vazgeç
              </button>
            </div>
          </div>
        </Dialog>
      )}
      <div style={styles.generalContainer}> 

      <div
        style={{
          backgroundColor: Primary.Background,
        }}
      >
        <DialogTitle>İşlemler</DialogTitle>
        <div
        // style={{backgroundColor:"black"}}
        >
        
        {imageUrl.mainImage ? <img 
        style={{
          maxWidth: '100%', // Ensures the image fits within its container's width
          maxHeight: '300px', // Limits the height to 500px, adjust as needed
          objectFit: 'contain', // Ensures the aspect ratio is maintained
        }}
        src={imageUrl.mainImage} 
        alt="Dynamic" /> : <p>Loading...</p>}
         {imageUrl.imageParts.length > 0 && (
          <div >
            {imageUrl.imageParts.map((partUrl, index) => (
              <img
                key={index}
                src={partUrl}
                alt={`Part ${index + 1}`}
                style={{
                  maxWidth: '100%', // Ensures the image fits within its container's width
                  maxHeight: '300px', // Limits the height to 500px, adjust as needed
                  objectFit: 'contain', // Ensures the aspect ratio is maintained
                }}
              />
            ))}
          </div>
      )}

        </div>
        <Divider />
      </div>

      <DialogContent>
        <div style={styles.container}>
          <Button
            onClick={() => onClickAnswerChange(row, "A")}
            style={styles.AnswerOpt}
          >
            {chAnswer("A")}
          </Button>
          <Button
            onClick={() => onClickAnswerChange(row, "B")}
            style={styles.AnswerOpt}
          >
            {chAnswer("B")}
          </Button>
          <Button
            onClick={() => onClickAnswerChange(row, "C")}
            style={styles.AnswerOpt}
          >
            {chAnswer("C")}
          </Button>
          <Button
            onClick={() => onClickAnswerChange(row, "D")}
            style={styles.AnswerOpt}
          >
            {chAnswer("D")}
          </Button>
          <Button
            onClick={() => onClickAnswerChange(row, "E")}
            style={styles.AnswerOpt}
          >
            {chAnswer("E")}
          </Button>
        </div>
        {showConfirm ? (
          <div
            style={{
              width: 400,
              borderRadius: 8,
              margin: 5,
              background: "#bcbcbc",
              justifyContent: "center",
              alignItems: "center",
              padding: 10,
            }}
          >
            <p style={{ textAlign: "center" }}>
              Cevap Değiştirmeyi Onaylıyormusun
            </p>

            <div
              style={{
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "row",
                width: "100%",
              }}
            >
              <ApproveButton
                text="Iptal"
                style={{
                  width: 120,
                  marginLeft: 10,
                  backgroundColor: Primary.LinkText,
                }}
                onClick={() => {
                  option = "";
                  setShowConfirm(false);
                }}
              />

              <ApproveButton
                text="Değiştir"
                style={{ width: 120, marginLeft: 10 }}
                onClick={() => {
                  if (cropId) {
                    props.onClickAnswerChangeNEW(cropIdDetails, option);
                  } else if (row) {
                    props.onClickAnswerChange(row, option);
                  } else return;
                }}
              />
            </div>
          </div>
        ) : (
          <></>
        )}
        <ApproveButton
          key="btn1"
          onClick={() => {
            if (cropId) {
              props.markAsDoneNEW(cropIdDetails);
            } else if (row) {
              props.markAsDone(row);
            } else return;
          }}
          text="Mark As Done"
        />
       {editorialSystemIdInfo === EditorialSystemId.CropPage && <ApproveButton
          key="btn3"
          onClick={() => {
            if (cropId) {
              props.rowClickNEW(cropIdDetails);
            } else if (row) {
              props.rowClick(row);
            } else return;
          }}
          text="Go To Crop Page"
        />}

        <ApproveButton
          key="btn4"
          onClick={() => {
            if (cropId) {
              getImageUrlNEW(true);
            } else if (row) {
              getImageUrl(true);
            } else {
              return;
            }
          }}
          text="Share Link"
        />
        {/* {(row && row[7].content) ? (
          <ApproveButton key="btn5" onClick={openVideo} text="Open Video" />
        ) : (
          false
        )}
        {cropIdDetails && cropIdDetails.AnswerVideoPath ? (
          <ApproveButton key="btn5" onClick={openVideoNEW} text="Open Video" />
        ) : (
          false
        )} */}
        {row && row[7].content ? (
          <ApproveButton key="btn5" onClick={openVideo} text="Open Video" />
        ) : cropIdDetails && cropIdDetails.AnswerVideoPath ? (
          <ApproveButton key="btn5" onClick={openVideoNEW} text="Open Video" />
        ) : (
          false
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            margin: "10px",
          }}
        >
          {(row && row[7].content) ||
          (cropIdDetails && cropIdDetails.AnswerVideoPath) ? (
            <button
              onClick={() => setDisplayDeleteVideoModal(true)}
              style={{ border: "1px solid red", color: "red" }}
            >
              Delete video
            </button>
          ) : (
            false
          )}
          {/* {cropIdDetails && cropIdDetails.AnswerVideoPath ? (
            <button
              onClick={() => setDisplayDeleteVideoModal(true)}
              style={{ border: "1px solid red", color: "red" }}
            >
              Delete video
            </button>
          ) : (
            false
          )} */}
          <button
            onClick={() => setDisplayDeleteQuestionModal(true)}
            style={{
              marginLeft: "10px",
              border: "1px solid red",
              color: "red",
            }}
          >
            Delete Question
          </button>
        </div>
        <div style={{ display: "flex", borderBottom: 1 }}>
          <p style={styles.id}>User Id</p>
          <p style={styles.desc1}>Description 1</p>
          <p style={styles.desc1}>Description2</p>
        </div>
        <Divider />
        <div style={{ scrollBehavior: "smooth" }}>
          {details.map((item) => {
            return (
              <div
                style={{
                  display: "flex",
                }}
              >
                <p style={styles.id}>{item.UserId}</p>
                <p style={styles.desc1}>{item.Description1}</p>
                <p style={styles.desc1}>{item.Description2}</p>
              </div>
            );
          })}
        </div>
      </DialogContent>
      </div>

    </Dialog>
  );
}
const styles = {
  generalContainer:{
    display:"flex",
    flexDirection:"row",
    width:"100%"
  },
  container: {
    display: "flex",
  },
  id: {
    width: 60,
    padding: 0,
    margin: 0,
    marginTop: 4,
  },
  desc1: {
    flex: 1,
    padding: 0,
    margin: 0,
    marginTop: 4,
  },
  AnswerOpt: {
    width: 20,
    padding: 1,
    backgroundColor: Primary.TopBar,
    color: Primary.Background,
    margin: 10,
  },
  deleteModalStyle: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    margin: "20px",
    color: "red",
  },
  deletebuttonStyle: {
    display: "flex",
    justifyContent: "center",
    gap: "10px",
    margin: "10px",
  },
  deleteConfirm: {
    minWidth: "100px",
    minHeight: "30px",
    color: "red",
    border: "3px solid red",
  },
  deleteConfirmClose: {
    minWidth: "100px",
    minHeight: "30px",
    color: "green",
    border: "3px solid green",
  },
};
export default ReportDialog;
