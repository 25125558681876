import { useState, useEffect } from "react";
import {  addCorporateSchool } from "../../../api/corporate/corporate";
import useAuth from "../../../hooks/useAuth";
import { toast } from "react-toastify";
import ResponseStatus from "../../../util/enums";


const useAddSchoolModalSubmit = () => {
    const { user, userAdminToken } = useAuth();
    const [ userCreatedSchoolInfo, setUserCorporateSchoolInfo] = useState(null)
    const [ isFromCreateSchool,setIsFromCreateSchool] = useState(false)
    const handleAddSchoolModalFormSubmit = async (formData) => {
        try {
            const res = await addCorporateSchool(formData,userAdminToken);
            if (res.data.ResponseStatus === ResponseStatus.Success) {
              toast.success("Corporate Başarıyle Oluşturuldu");
              setUserCorporateSchoolInfo(res.data.ResponseData)
              setIsFromCreateSchool(true)
            } else {
              toast.error("Bir Hata Oluştu");
            }
          } catch (error) {
            toast.error("Bir Hata Oluştu");
          }
        
    }

    return  {handleAddSchoolModalFormSubmit,userCreatedSchoolInfo,setUserCorporateSchoolInfo,isFromCreateSchool}
}

export default useAddSchoolModalSubmit