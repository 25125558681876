import { useState, useEffect } from "react";

const useCorporateForm = (initialValues = {}) => {
  const [formValues, setFormValues] = useState({
    Id:"",
    Name: "",
    UserId: "",
    PromotionName: "",
    HasLottery: false,
    RowStatus: false,
    ...initialValues, // Set initial values from props if provided
  });

  console.log("formValues",formValues);

//   useEffect(() => {
//     if (initialValues) {
//       setFormValues((prevValues) => ({
//         ...prevValues,
//         ...initialValues,
//       }));
//     }
//   }, [initialValues]);

  // Generic change handler
  const handleChange = (e) => {
    const { name, value, type } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: type === "select-one" ? value === "true" : value, // Convert to boolean if select
    }));
  };

  // Function to reset the form values (useful for resetting after a submit)
  const resetForm = () => {
    setFormValues({
      Id:"",
      Name: "",
      UserId: "",
      PromotionName: "",
      HasLottery: false,
      RowStatus: false,
      ...initialValues,
    });
  };

  return {
    formValues,
    handleChange,
    resetForm,
    setFormValues, // Exporting this if we need direct access to updating formValues
  };
};

export default useCorporateForm;
