import { useState, useEffect } from "react";
import { addCorporate } from "../../../api/corporate/corporate";
import useAuth from "../../../hooks/useAuth";
import { toast } from "react-toastify";
import ResponseStatus from "../../../util/enums";


const useModalSubmit = () => {
    const { user, userAdminToken } = useAuth();
    const [ userCreatedCorporateInfo, setUserCreatedCorporateInfo] = useState(null)
    const [isFromAddCorporate,setIsFromAddCorporate] =useState(false)
    const handleModalFormSubmit = async (formData) => {
        try {
            const res = await addCorporate(formData,userAdminToken);
            if (res.data.ResponseStatus === ResponseStatus.Success) {
              toast.success("Corporate Başarıyle Oluşturuldu");
              setUserCreatedCorporateInfo(res.data.ResponseData)
              setIsFromAddCorporate(true)
            } else {
              toast.error("Bir Hata Oluştu");
            }
          } catch (error) {
            toast.error("Bir Hata Oluştu");
          }
        
    }

    return  {handleModalFormSubmit,userCreatedCorporateInfo,setUserCreatedCorporateInfo,isFromAddCorporate}
}

export default useModalSubmit