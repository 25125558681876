import React, { useEffect, useState } from 'react'
import { useMediaQuery } from "react-responsive";
import SideBar from "../components/SideBar";
import { Components } from "../assets/Dimensions";
import { corprateInfos, localCacheRequest,couponconfigInfos } from '../api/CacheAndLogs';
import CacheButton from '../components/CacheButton';
import SwitchComponent from '../components/SwitchComponent';
import './cache.css'
import { ToastContainer,toast } from 'react-toastify';
import ExamCategoryType from '../enums/ExamCategoryType';
import { GetObjectKey } from '../helpers/getObjectKeyFromValue';



const Caches = () => {
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 700px)' })

    const [nav, setNav] = useState(false);
    const [userId, setUserId] = useState('');
    const [examCategory, setExamCategory] = useState('');
    const [examCategoryType, setExamCategoryType] = useState('');
    const [corprateData, setCorprateData] = useState();
    const [couponConfigData, setCouponConfigData] = useState();
    const [corprateNames,setCorprateNames] = useState()
    const [selectedName, setSelectedName] = useState('');
    const [selectedConfigType, setSelectedConfigType] = useState(null);
    const [selectedId, setSelectedId] = useState(null);
    const [selectedValue, setSelectedValue] = useState('DevTest');

    const pJWT = sessionStorage.getItem("jwtP");
    const _user = JSON.parse(localStorage.getItem("user"));


    const notify = (notif) =>{
        toast.success(notif)
    }

  const handleValueChange = (newValue) => {
    setSelectedValue(newValue);
  };


    const handleInputChange = (event) => {
        const enteredValue = event.target.value;
        // Use a regular expression to check if the enteredValue is a valid number
        const isValidNumber = /^\d+$/.test(enteredValue);

        // Only update the state if the enteredValue is a valid number
        if (isValidNumber || enteredValue === '') {
            setUserId(enteredValue);
        }
    };
    const handleExamCategory = (event) => {
        const enteredValue = event.target.value;
        // Use a regular expression to check if the enteredValue is a valid number
        const isValidNumber = /^\d+$/.test(enteredValue);

        // Only update the state if the enteredValue is a valid number
        if (isValidNumber || enteredValue === '') {
            setExamCategory(enteredValue);
        }
    };


    const handleSelectChange = (event) => {
        // Get the selected name from the event
        const selectedName = event.target.value;
        setSelectedName(selectedName);
    
        // Find the corresponding 'Id' from the 'ResponseData' array using the selected name
        const selectedNameData = corprateData.find((item) => item.Name === selectedName);
        if (selectedNameData) {
          // Set the selected 'Id' to the state
          setSelectedId(selectedNameData.Id);
        } else {
          // Handle the case if the selected name is not found (optional)
          setSelectedId(null);
        }
      };

      const handleConfigTypeSelectChange = (event) => {
       
        const selectedConfigType = event.target.value;
        setSelectedConfigType(selectedConfigType);
    
       
      };
    const getCorprateNames = async () =>{
        const res = await corprateInfos(selectedValue=="Admin"?pJWT:_user.Token,selectedValue=="Admin"?true:false);
        if(res.status == 200){
            console.log("what")
            if(res.data.ResponseStatus == 1) {
                console.log("nerdere")
                 //const corpratesFromResponse = res.data.ResponseData?.map((item) => item.Name);
                setCorprateData(res.data.ResponseData)
               // setCorprateNames(corpratesFromResponse)
            }
        }
        console.log(corprateNames)
    }
    const getCouponConfigs = async () =>{
        const res = await couponconfigInfos(selectedValue=="Admin"?pJWT:_user.Token,selectedValue=="Admin"?true:false);
        if(res.status == 200){
            
            if(res.data.ResponseStatus == 1) {
                
                 //const corpratesFromResponse = res.data.ResponseData?.map((item) => item.Name);
                setCouponConfigData(res.data.ResponseData)
               // setCorprateNames(corpratesFromResponse)
            }
        }
        console.log(corprateNames)
    }
    const style = {
        container: {
            //state e bool değer ekle menü butonuna basıldığında css 
            //içinde container a margin eklesin hemde navı açsın

            marginLeft: isTabletOrMobile ? 0 : Components.SideBarWidth + 15,
            paddingTop: 20,
            marginRight: 30,
            marginBottom: 20

        },
    }

    return (
        <div>
            <ToastContainer/>
            {isTabletOrMobile ?
                <div>
                    <span style={{ "position": "absolute", top: 10, right: 10 }} onClick={() => (setNav(!nav))}>menu</span>
                    {nav && <SideBar />}

                </div>
                : <SideBar />}

            <div style={style.container}>
            <SwitchComponent onValueChange={handleValueChange} />
            
                <div className="table-container">
                    <table className="responsive-table">
                        {/* Local Memory Start */}
                        <thead>
                            <tr><td colSpan="3" style={{ fontSize: 30 }}>Local Memory Cache</td></tr>
                            <tr>
                                <th>Cache İsmi</th>
                                <th>Gerekli Parametreler</th>
                                <th>Cache Kır</th>
                            </tr>
                        </thead>
                        <tbody>
                            {/* Lectures start */}
                            <tr><td colSpan="2" style={{ fontSize: 25 }}>Lectures</td></tr>
                            {/* <tr>
                                <td>All Questions</td>
                                <td></td>
                                <td> <CacheButton notify={notify} apiEndpoint={"allQuestions"} buttonText={"allQuestions"} isAdmin={selectedValue=="Admin"?true:false}/></td>
                            </tr> */}

                            <tr>
                                <td>Lectures</td>
                                <td>Empty</td>
                                <td><CacheButton notify={notify} isAdmin={selectedValue=="Admin"?true:false} apiEndpoint={"lectures"} buttonText={"lectures"} /></td>
                            </tr>
                            <tr>
                                <td>nestedTitles</td>
                                <td>Empty</td>
                                <td><CacheButton notify={notify} isAdmin={selectedValue=="Admin"?true:false} apiEndpoint={"nestedTitles"} buttonText={"nestedTitles"} /></td>
                            </tr>
                            <tr>
                                <td>groupedNestedTitles</td>
                                <td>Empty</td>
                                <td><CacheButton notify={notify} isAdmin={selectedValue=="Admin"?true:false} apiEndpoint={"groupedNestedTitles"} buttonText={"groupedNestedTitles"} /></td>
                            </tr>
                            <tr>
                                <td>nestedAchievements</td>
                                <td>Empty</td>
                                <td><CacheButton notify={notify} isAdmin={selectedValue=="Admin"?true:false} apiEndpoint={"nestedAchievements"} buttonText={"nestedAchievements"} /></td>
                            </tr>
                            <tr>
                                <td>suggestedPlans</td>
                                <td>Empty</td>
                                <td><CacheButton notify={notify} isAdmin={selectedValue=="Admin"?true:false} apiEndpoint={"suggestedPlans"} buttonText={"suggestedPlans"} /></td>
                            </tr>
                            {/* Lectures end */}
                            {/* --------------------------------- */}
                            {/* Lotteries Start */}
                            <tr><td colSpan="2" style={{ fontSize: 25 }}>Lotteries</td></tr>
                            <tr>
                                <td>lotteries</td>
                                <td>Empty</td>
                                <td><CacheButton notify={notify} isAdmin={selectedValue=="Admin"?true:false} apiEndpoint={"lotteries"} buttonText={"lotteries"} /></td>
                            </tr>
                            <tr>
                                <td>completedLotteries</td>
                                <td>Empty</td>
                                <td><CacheButton notify={notify} isAdmin={selectedValue=="Admin"?true:false} apiEndpoint={"completedLotteries"} buttonText={"completedLotteries"} /></td>
                            </tr>
                            <tr>
                                <td>lotteryConfigs</td>
                                <td>Empty</td>
                                <td><CacheButton notify={notify} isAdmin={selectedValue=="Admin"?true:false} apiEndpoint={"lotteryConfigs"} buttonText={"lotteryConfigs"} /></td>
                            </tr>
                            <tr>
                                <td>lotteryConfigTypes</td>
                                <td>Empty</td>
                                <td><CacheButton notify={notify} isAdmin={selectedValue=="Admin"?true:false} apiEndpoint={"lotteryConfigTypes"} buttonText={"lotteryConfigTypes"} /></td>
                            </tr>
                            <tr>
                                <td>corporatelotteriesPromotionNames</td>
                                <td>Empty</td>
                                <td><CacheButton notify={notify} isAdmin={selectedValue=="Admin"?true:false} apiEndpoint={"corporatelotteriesPromotionNames"} buttonText={"corporatelotteriesPromotionNames"} /></td>
                            </tr>
                            <tr>
                                <td>corporatelotteries</td>
                                <td>
                                    
                                    {corprateData&&<select value={selectedName} onChange={handleSelectChange}>
                                        <option value="">Select a corprate Name</option>
                                        {corprateData.map((name) => (
                                            <option key={name.Name} value={name.Name}>
                                                {name.Name}
                                            </option>
                                        ))}
                                    </select>}
                                    <button onClick={()=>getCorprateNames()}>
                                        getCorprateNames
                                    </button>
                                </td>
                                <td><CacheButton notify={notify} isAdmin={selectedValue=="Admin"?true:false} apiEndpoint={`corporatelotteries${selectedId}`} buttonText={"corporatelotteries"} disabled={selectedId == null ?true: false} /></td>
                            </tr>
                            {/* Lotteries end */}
                            {/* --------------------------------- */}
                            {/* Credits Start */}
                            <tr><td colSpan="2" style={{ fontSize: 25 }}>Credits</td></tr>
                            <tr>
                                <td>clear-user-credit</td>
                                <td> <input
                                placeholder='Please Enter UserId'
                                    type="text"
                                    value={userId}
                                    onChange={handleInputChange}
                                /></td>
                                <td><CacheButton notify={notify} isAdmin={selectedValue=="Admin"?true:false} apiEndpoint={`clear-user-credit?userId=${userId}`} buttonText={"clear-user-credit?userId=(int)"} isCleanCache={true} disabled={userId.length == 0 ?true: false} /></td>
                            </tr>
                            <tr>
                                <td>clear-all-credits</td>
                                <td>Empty</td>
                                <td><CacheButton notify={notify} isAdmin={selectedValue=="Admin"?true:false} apiEndpoint={"clear-all-credits"} buttonText={"clear-all-credits"} isCleanCache={true} /></td>
                            </tr>
                            {/* Credits End */}
                            {/* --------------------------------- */}
                            {/* Online Lectures Start */}
                            <tr><td colSpan="2" style={{ fontSize: 25 }}>Online Lectures</td></tr>
                            <tr>
                                <td>clear-online-lecture-by-examcategory YKS</td>
                                <td> Empty</td>
                                <td><CacheButton notify={notify} isAdmin={selectedValue=="Admin"?true:false} apiEndpoint={"clear-online-lecture-by-examcategory?examCategoryId=1"} buttonText={"online Lecture YKS"} isCleanCache={true} /></td>
                            </tr>
                            <tr>
                                <td>clear-online-lecture-by-examcategory LGS</td>
                                <td> Empty</td>
                                <td><CacheButton notify={notify} isAdmin={selectedValue=="Admin"?true:false} apiEndpoint={"clear-online-lecture-by-examcategory?examCategoryId=2"} buttonText={"online Lecture LGS"} isCleanCache={true} /></td>
                            </tr>
                            <tr>
                                <td>clear-online-lecture (All)</td>
                                <td>Empty</td>
                                <td><CacheButton notify={notify} isAdmin={selectedValue=="Admin"?true:false} apiEndpoint={"clear-online-lecture"} buttonText={"clear-online-lecture"} isCleanCache={true} /></td>
                            </tr>
                            {/* Online Lectures End */}
                            {/* --------------------------------- */}
                            {/* ProductStores Start */}
                            <tr><td colSpan="2" style={{ fontSize: 25 }}>Product Stores</td></tr>
                            <tr>
                                <td>productStore</td>
                                <td> Empty</td>
                                <td><CacheButton notify={notify} isAdmin={selectedValue=="Admin"?true:false} apiEndpoint={"productStore"} buttonText={"productStore"} /></td>
                            </tr>
                            {/* ProductStores End */}
                            {/* --------------------------------- */}
                            {/* Coupon Start */}
                            <tr><td colSpan="2" style={{ fontSize: 25 }}>Coupon</td></tr>
                            <tr>
                                <td>couponConfigs</td>
                                <td>Empty</td>
                                <td><CacheButton notify={notify} isAdmin={selectedValue=="Admin"?true:false} apiEndpoint={"couponConfigs"} buttonText={"couponConfigs"} /></td>
                            </tr>
                            <tr>
                                <td>couponConfigTypes</td>
                                <td>
                                {couponConfigData&&<select value={selectedConfigType} onChange={handleConfigTypeSelectChange}>
                                        <option value="">Select a config type</option>
                                        {couponConfigData.map((name) => (
                                            <option key={name.Name} value={name.Name}>
                                                {name.Name}
                                            </option>
                                        ))}
                                    </select>}
                                <button onClick={()=>getCouponConfigs()}>
                                        getCouponConfigs
                                    </button>
                                </td>
                                <td><CacheButton notify={notify} isAdmin={selectedValue=="Admin"?true:false} apiEndpoint={`getCouponConfigs_${selectedConfigType}`} buttonText={"couponConfigTypes"} disabled={selectedConfigType == null ?true: false} /></td>
                            </tr>
                            <tr>
                                <td>couponDefinitions</td>
                                <td>Empty</td>
                                <td><CacheButton notify={notify} isAdmin={selectedValue=="Admin"?true:false} apiEndpoint={"couponDefinitions"} buttonText={"couponDefinitions"} /></td>
                            </tr>
                            {/* Coupon End */}
                            {/* --------------------------------- */}
                            {/* ProcessUserRankRewardAsync Start */}
                            <tr><td colSpan="2" style={{ fontSize: 25 }}>ProcessUserRankRewardAsync</td></tr>
                            <tr>
                                <td>ProcessUserRankRewardsAsync YKS</td>
                                <td> DATA 2</td>
                                <td><CacheButton notify={notify} isAdmin={selectedValue=="Admin"?true:false} apiEndpoint={"ProcessUserRankRewardsAsync_1"} buttonText={"ProcessUserRankRewardsAsync_YKS"} /></td>
                            </tr>
                            <tr>
                                <td>ProcessUserRankRewardsAsync LGS</td>
                                <td>Empty</td>
                                <td><CacheButton notify={notify} isAdmin={selectedValue=="Admin"?true:false} apiEndpoint={"ProcessUserRankRewardsAsync_2"} buttonText={"ProcessUserRankRewardsAsync_LGS"} /></td>
                            </tr>
                            {/* ProcessUserRankRewardAsync End */}
                            {/* --------------------------------- */}
                            {/* TrialExam Start */}
                            <tr><td colSpan="2" style={{ fontSize: 25 }}>Trial Exams</td></tr>
                            <tr>
                                <td>trialExamCalculations</td>
                                <td>Empty</td>
                                <td><CacheButton notify={notify} isAdmin={selectedValue=="Admin"?true:false} apiEndpoint={"trialExamCalculations"} buttonText={"trialExamCalculations"} /></td>
                            </tr>
                            <tr>
                                <td>trialExamLgsCalculations</td>
                                <td>Empty</td>
                                <td><CacheButton notify={notify} isAdmin={selectedValue=="Admin"?true:false} apiEndpoint={"trialExamLgsCalculations"} buttonText={"trialExamLgsCalculations"} /></td>
                            </tr>
                            <tr>
                                <td>trialExamLgsQuartiles</td>
                                <td>Empty</td>
                                <td><CacheButton notify={notify} isAdmin={selectedValue=="Admin"?true:false} apiEndpoint={"trialExamLgsQuartiles"} buttonText={"trialExamLgsQuartiles"} /></td>
                            </tr>
                            <tr>
                                <td>trialExams</td>
                                <td>Empty</td>
                                <td><CacheButton notify={notify} isAdmin={selectedValue=="Admin"?true:false} apiEndpoint={"trialExams"} buttonText={"trialExams"} /></td>
                            </tr>
                            <tr>
                                <td>trialExamSessionTypes</td>
                                <td>Empty</td>
                                <td><CacheButton notify={notify} isAdmin={selectedValue=="Admin"?true:false} apiEndpoint={"trialExamSessionTypes"} buttonText={"trialExamSessionTypes"} /></td>
                            </tr>
                            <tr>
                                <td>trialExamTestQuestions</td>
                                <td>Empty</td>
                                <td><CacheButton notify={notify} isAdmin={selectedValue=="Admin"?true:false} apiEndpoint={"trialExamTestQuestions"} buttonText={"trialExamTestQuestions"} /></td>
                            </tr>
                            <tr>
                                <td>trialExamTests</td>
                                <td>Empty</td>
                                <td><CacheButton notify={notify} isAdmin={selectedValue=="Admin"?true:false} apiEndpoint={"trialExamTests"} buttonText={"trialExamTests"} /></td>
                            </tr>
                            <tr>
                                <td>cleartrialexam</td>
                                <td>Empty</td>
                                <td><CacheButton notify={notify} isAdmin={selectedValue=="Admin"?true:false} apiEndpoint={"cleartrialexam"} buttonText={"cleartrialexam"} isCleanCache = {true}/></td>
                            </tr>
                            <tr>
                                <td>universityExams</td>
                                <td>Empty</td>
                                <td><CacheButton notify={notify} isAdmin={selectedValue=="Admin"?true:false} apiEndpoint={"universityExams"} buttonText={"universityExams"} /></td>
                            </tr>
                            {/* TrialExam End */}
                            {/* --------------------------------- */}
                            {/* Configuration Table Start */}
                            <tr><td colSpan="2" style={{ fontSize: 25 }}>Configuration Table</td></tr>
                            <tr>
                                <td>Configuration Table</td>
                                <td>Empty</td>
                                <td><CacheButton notify={notify} isAdmin={selectedValue=="Admin"?true:false} apiEndpoint={"configurations"} buttonText={"configurations"} /></td>
                            </tr>
                            {/* Configuration Table End */}
                            {/* --------------------------------- */}
                            {/* Navigation Splash Screen Start */}
                            <tr><td colSpan="2" style={{ fontSize: 25 }}>Navigation Splash Screen</td></tr>
                            <tr>
                                <td>Navigation Splash Screen</td>
                                <td>Empty</td>
                                <td><CacheButton notify={notify} isAdmin={selectedValue=="Admin"?true:false} apiEndpoint={"navigationPageSplashScreen"} buttonText={"navigationPageSplashScreen"} /></td>
                            </tr>
                            <tr>
                                <td>Navigation Splash Screen (new)</td>
                                <td> <input
                                placeholder='Please Enter ExamCategory'
                                    type="text"
                                    value={examCategory}
                                    onChange={handleExamCategory}
                                /></td>
                                <td><CacheButton notify={notify} isAdmin={selectedValue=="Admin"?true:false} apiEndpoint={`navigationPageSplashScreen_${examCategory}`} buttonText={"navigationPageSplashScreen_(examCategoryId)"}  disabled={examCategory.length == 0 ?true: false} /></td>
                            </tr>
                            {/* Navigation Splash Screen End */}
                            {/* --------------------------------- */}
                            {/* premiumPagePromote Start */}
                            <tr><td colSpan="2" style={{ fontSize: 25 }}>Premium Page Promote</td></tr>
                            <tr>
                                <td>Premium Page Promote</td>
                                <td>Empty</td>
                                <td><CacheButton notify={notify} isAdmin={selectedValue=="Admin"?true:false} apiEndpoint={"premiumPagePromote"} buttonText={"premiumPagePromote"} /></td>
                            </tr>
                            {/* premiumPagePromote End */}
                            {/* --------------------------------- */}
                            {/* Duello Start */}
                            <tr><td colSpan="2" style={{ fontSize: 25 }}>Duello lgs </td></tr>
                            <tr>
                                <td>Duello LGS</td>
                                <td>Empty</td>
                                <td>
                                    <CacheButton notify={notify} isAdmin={selectedValue=="Admin"?true:false} apiEndpoint={"https://lgsduello.tosanalytics.com/cache/clear-duello"} buttonText={"LGS Duello"} isDuello={true}/>
                                </td>
                            </tr>
                            <tr><td colSpan="2" style={{ fontSize: 25 }}>Duello YKS</td></tr>
                            <tr>
                                <td>Duello YKS</td>
                                <td>Empty</td>
                                <td>
                                    <CacheButton notify={notify} isAdmin={selectedValue=="Admin"?true:false} apiEndpoint={"https://duello.tosanalytics.com/cache/clear-duello"} buttonText={"YKS Duello"} isDuello={true}/>
                                </td>
                            </tr>
                            {/* Duello End */}
                            {/* --------------------------------- */}
                            {/* Version Update Config Cache Start */}
                            <tr><td colSpan="2" style={{ fontSize: 25 }}>Version Update Config Cache</td></tr>
                            <tr>
                                <td>Version Update Config Cache</td>
                                <td>Empty</td>
                                <td><CacheButton notify={notify} isAdmin={selectedValue=="Admin"?true:false} apiEndpoint={"VersionUpdateCheckVersion"} buttonText={"VersionUpdateCheckVersion"} /></td>
                            </tr>
                            {/* Version Update Config Cache End */}
                            {/* --------------------------------- */}
                            {/* content Managment Start */}
                            <tr><td colSpan="2" style={{ fontSize: 25 }}>ContentManagement</td></tr>
                            <tr>
                                <td>Clear Navigation Flag</td>
                                <td>Bütün navigation page cachelerini kırar</td>
                                  <td><CacheButton notify={notify} isAdmin={selectedValue=="Admin"?true:false} apiEndpoint={"clearnavigationflag"} buttonText={"ClearNavigationFlag"} /></td>
                            </tr>
                            <tr>
                                <td>Content Process</td>
                                <td>s3 de olan bütün dosyaların cache</td>
                                <td>
                                    <CacheButton notify={notify} isAdmin={selectedValue=="Admin"?true:false} apiEndpoint={"https://contentprocess.tosanalytics.com/api/cache/clear?key=s3folders"} buttonText={"contentprocess"} isDuello={true} isContentProcess={true}/>
                                </td>
                            </tr>
                            {/* content Managment End */}
                            {/* --------------------------------- */}
                            {/* Local Memory end */}
                            {/* -------------------------------------------------------------- */}
                            {/* Redis Cache Start */}
                        </tbody>
                        <tr><td colSpan="3" style={{ fontSize: 30 }}>Redis Cache</td></tr>
                        <thead >
                            <tr>
                                <th>Redis Key</th>
                                <th>Database</th>
                                <th>Description</th>
                            </tr>
                        </thead>
                        <tbody>

                            {/* --------------------------------- */}
                            {/* Trial Exams Start */}
                            <tr><td colSpan="2" style={{ fontSize: 25 }}>Trial Exams</td></tr>
                            <tr>
                                <td>LGS_CALCULATION_STATE</td>
                                <td>TrialExam</td>
                                <td>son hesaplama durumu</td>
                            </tr>
                            <tr>
                                <td>LGS_LAST_CALCULATION_TIME</td>
                                <td>TrialExam</td>
                                <td>son hesaplama süresi</td>
                            </tr>
                            <tr>
                                <td>LGS_NET_(trialExamId)</td>
                                <td>TrialExam</td>
                                <td>lgs neti</td>
                            </tr>
                            <tr>
                                <td>LGS_QUARTILE_(trialExamId)_(quartile.Year)</td>
                                <td>TrialExam</td>
                                <td>deneme sınavı lgs çeyrekleri</td>
                            </tr>
                            <tr>
                                <td>LGS_RANK_(trailExamId)_(quartile.Year)</td>
                                <td>TrialExam</td>
                                <td>lgs sıralaması</td>
                            </tr>
                            <tr>
                                <td>LGS_TO_CALCULATE_EXAMS</td>
                                <td>TrialExam</td>
                                <td>kullanıcı sınavı bitirince hesaplaması</td>
                            </tr>
                            <tr>
                                <td>TRIALEXAM_PARTICIPANT_(trailExamId)</td>
                                <td>TrialExam</td>
                                <td>deneme sınavı katılımcıları</td>
                            </tr>
                            {/* Trial Exams End */}
                            {/* --------------------------------- */}
                            {/* TestSection Start */}
                            <tr><td colSpan="2" style={{ fontSize: 25 }}>TestSection</td></tr>
                            <tr>
                                <td>DAILY_TESTSECTIONS_COUNT</td>
                                <td>TestSection</td>
                                <td>Empty</td>
                            </tr>
                            <tr>
                                <td>TESTSECTIONS_TESTSECTIONELEMENTSTATS</td>
                                <td>TestSection</td>
                                <td>Empty</td>
                            </tr>
                            <tr>
                                <td>TESTSECTIONS_TESTSECTIONELEMENTSTATS_HASHSET</td>
                                <td>TestSection</td>
                                <td>testlerin hashlenip getirilmiş hali</td>
                            </tr>
                            {/* TestSection End */}
                            {/* --------------------------------- */}
                            {/* Restrictions Start */}
                            <tr><td colSpan="2" style={{ fontSize: 25 }}>Restrictions</td></tr>
                            <tr>
                                <td>DEVICE_RESTRICTION_TEST_SECTIONS</td>
                                <td>Restriction</td>
                                <td>cihaz kısıtlamaları</td>
                            </tr>
                            <tr>
                                <td>USER_RESTRICTION_TEST_SECTIONS</td>
                                <td>Restriction</td>
                                <td>kullanıcı kısıtlamalı testler</td>
                            </tr>
                            {/* Restrictions End */}
                            {/* --------------------------------- */}
                            {/* OnlineLectures Start */}
                            <tr><td colSpan="2" style={{ fontSize: 25 }}>Online Lectures</td></tr>
                            <tr>
                                <td>_ONLINE_LECTURE_PREMIUM_LIST_1</td>
                                <td>OnlineLecture</td>
                                <td>YKS premium listesi</td>
                            </tr>
                            <tr>
                                <td>_ONLINE_LECTURE_FREE_LIST_1</td>
                                <td>OnlineLecture</td>
                                <td>YKS free listesi</td>
                            </tr>
                            <tr>
                                <td>_ONLINE_LECTURE_PREMIUM_LIST_2</td>
                                <td>OnlineLecture</td>
                                <td>LGS premium listesi</td>
                            </tr>
                            <tr>
                                <td>_ONLINE_LECTURE_FREE_LIST_2</td>
                                <td>OnlineLecture</td>
                                <td>LGS free listesi </td>
                            </tr>
                            <tr>
                                <td>clear-online-lecture</td>
                                <td>OnlineLecture</td>
                                <td>genel canlı dershane listesi</td>
                            </tr>
                            {/* OnlineLectures End */}
                            {/* --------------------------------- */}
                            {/* Lottery Start */}
                            <tr><td colSpan="2" style={{ fontSize: 25 }}>Lottery</td></tr>
                            <tr>
                                <td>CORPORATE_LOTTERY_TOTALTICKETCOUNT_(continuingLotteryId)</td>
                                <td>Lottery</td>
                                <td>kurumsal çekilişin toplam ticket'ı</td>
                            </tr>
                            <tr>
                                <td>CORPORATE_LOTTERY_USERTICKET_(userId)</td>
                                <td>Lottery</td>
                                <td>kurumsal çekilişe katılan kullanıcıların tekil puanları</td>
                            </tr>
                            <tr>
                                <td>LOTTERY_TOTALTICKETCOUNT_(continuingLotteryId)</td>
                                <td>Lottery</td>
                                <td>çekilişin toplam ticket'ı</td>
                            </tr>
                            <tr>
                                <td>LOTTERY_USERTICKET_(userId)</td>
                                <td>Lottery</td>
                                <td>çekilişe katılan kullanıcıların tekil puanları</td>
                            </tr>
                            {/* Lottery End */}
                            {/* --------------------------------- */}
                            {/* Invitation Start */}
                            <tr><td colSpan="2" style={{ fontSize: 25 }}>Invitation</td></tr>
                            <tr>
                                <td>INVITATION_CODES</td>
                                <td>Invitation</td>
                                <td>invite kodları</td>
                            </tr>
                            <tr>
                                <td>INVITATION_REGISTERED_DEVICES</td>
                                <td>Invitation</td>
                                <td>invite kayıtlı cihazlar</td>
                            </tr>
                            <tr>
                                <td>INVITATION_TOTAL_COUNT</td>
                                <td>Invitation</td>
                                <td>toplam davet kodu</td>
                            </tr>
                            <tr>
                                <td>INVITATION_USER_DATA</td>
                                <td>Invitation</td>
                                <td>kullanıcının invite kodu</td>
                            </tr>
                            {/* Invitation End */}
                            {/* --------------------------------- */}
                            {/* FortuneOfWheel Start */}
                            <tr><td colSpan="2" style={{ fontSize: 25 }}>FortuneOfWheel</td></tr>
                            <tr>
                                <td>_FORTUNE_OF_WHEEL</td>
                                <td>FortuneOfWheel</td>
                                <td>Empty</td>
                            </tr>
                            <tr>
                                <td>_FORTUNE_OF_WHEEL_BOX_GIFT-(userId)</td>
                                <td>FortuneOfWheel</td>
                                <td>çarkdaki hediyesi</td>
                            </tr>
                            <tr>
                                <td>_FORTUNE_OF_WHEEL_DISCOUNTED_GIFT-(userId)</td>
                                <td>FortuneOfWheel</td>
                                <td>indirim kazanan kullanıcı</td>
                            </tr>
                            <tr>
                                <td>_FORTUNE_OF_WHEEL_GIFT</td>
                                <td>FortuneOfWheel</td>
                                <td>Empty</td>
                            </tr>
                            <tr>
                                <td>_FORTUNE_OF_WHEEL_USER_GIFT-(userId)@(fortuneSectionType)</td>
                                <td>FortuneOfWheel</td>
                                <td>kullanıcının kazandığı hediye</td>
                            </tr>
                            <tr>
                                <td>_GIFT2WIN-(userId)</td>
                                <td>FortuneOfWheel</td>
                                <td>kullanıcının kazanacak olduğu hediye</td>
                            </tr>
                            {/* FortuneOfWheel End */}
                            {/* --------------------------------- */}
                            {/* Coupon Start */}
                            <tr><td colSpan="2" style={{ fontSize: 25 }}>Coupon</td></tr>
                            <tr>
                                <td>COUPON_USER_ADS_RESTRICTION-(userId)</td>
                                <td>Coupon</td>
                                <td>kullanıcı kupon reklamları kısıtlaması</td>
                            </tr>
                            <tr>
                                <td>COUPON_USER_ADS_RESTRICTION_COUNT-(userId)</td>
                                <td>Coupon</td>
                                <td>kupon kullanıcı reklamları kısıtlama sayısı</td>
                            </tr>
                            <tr>
                                <td>COUPON_USERCOUPONS</td>
                                <td>Coupon</td>
                                <td>kullanıcı kuponları</td>
                            </tr>
                            {/* Coupon End */}
                            {/* --------------------------------- */}
                            {/* Content Managment Start */}
                            <tr><td colSpan="2" style={{ fontSize: 25 }}>ContentManagement</td></tr>
                            <tr>
                                <td>_NAVIGATION_INFO-oldversion-1-true&database=ContentManagement(OLD VERSİON YKS Light Mode)</td>
                                <td>Ana sayfada menülerin yerleri, görselleri ve yazıları</td>
                                  <td><CacheButton notify={notify} isAdmin={selectedValue=="Admin"?true:false} apiEndpoint={"_NAVIGATION_INFO-oldversion-1-true&database=ContentManagement"} buttonText={"OLD VERSİON YKS Light Mode"} isRedis={true}/></td>
                            </tr>
                            <tr>
                                <td>_NAVIGATION_INFO-oldversion-1-false&database=ContentManagement(OLD VERSİON YKS DARK Mode)</td>
                                <td>Ana sayfada menülerin yerleri, görselleri ve yazıları</td>
                                  <td><CacheButton notify={notify} isAdmin={selectedValue=="Admin"?true:false} apiEndpoint={"_NAVIGATION_INFO-oldversion-1-false&database=ContentManagement"} buttonText={"OLD VERSİON YKS DARK Mode"} isRedis={true}/></td>
                            </tr>
                            <tr>
                                <td>_NAVIGATION_INFO-newversion-1-true&database=ContentManagement(OLD VERSİON YKS Light Mode)</td>
                                <td>Ana sayfada menülerin yerleri, görselleri ve yazıları</td>
                                  <td><CacheButton notify={notify} isAdmin={selectedValue=="Admin"?true:false} apiEndpoint={"_NAVIGATION_INFO-newversion-1-true&database=ContentManagement"} buttonText={"NEW VERSİON YKS Light Mode"} isRedis={true}/></td>
                            </tr>
                            <tr>
                                <td>_NAVIGATION_INFO-newversion-1-false&database=ContentManagement(OLD VERSİON YKS DARK Mode)</td>
                                <td>Ana sayfada menülerin yerleri, görselleri ve yazıları</td>
                                  <td><CacheButton notify={notify} isAdmin={selectedValue=="Admin"?true:false} apiEndpoint={"_NAVIGATION_INFO-newversion-1-false&database=ContentManagement"} buttonText={"NEW VERSİON YKS DARK Mode"} isRedis={true}/></td>
                            </tr>
                            <tr>
                                <td>_NAVIGATION_INFO-oldversion-2-true&database=ContentManagement(LGS Light Mode)</td>
                                <td>Ana sayfada menülerin yerleri, görselleri ve yazıları</td>
                                  <td><CacheButton notify={notify} isAdmin={selectedValue=="Admin"?true:false} apiEndpoint={"_NAVIGATION_INFO-oldversion-2-true&database=ContentManagement"} buttonText={"OLD VERSİON LGS Light Mode"} isRedis={true}/></td>
                            </tr>
                            <tr>
                                <td>_NAVIGATION_INFO-oldversion-2-false&database=ContentManagement(LGS DARK Mode)</td>
                                <td>Ana sayfada menülerin yerleri, görselleri ve yazıları</td>
                                  <td><CacheButton notify={notify} isAdmin={selectedValue=="Admin"?true:false} apiEndpoint={"_NAVIGATION_INFO-oldversion-2-false&database=ContentManagement"} buttonText={"OLD VERSİON LGS DARK Mode"} isRedis={true}/></td>
                            </tr>
                            <tr>
                                <td>_NAVIGATION_INFO-newversion-2-true&database=ContentManagement(LGS Light Mode)</td>
                                <td>Ana sayfada menülerin yerleri, görselleri ve yazıları</td>
                                  <td><CacheButton notify={notify} isAdmin={selectedValue=="Admin"?true:false} apiEndpoint={"_NAVIGATION_INFO-newversion-2-true&database=ContentManagement"} buttonText={"NEW VERSİON LGS Light Mode"} isRedis={true}/></td>
                            </tr>
                            <tr>
                                <td>_NAVIGATION_INFO-newversion-2-false&database=ContentManagement(LGS DARK Mode)</td>
                                <td>Ana sayfada menülerin yerleri, görselleri ve yazıları</td>
                                  <td><CacheButton notify={notify} isAdmin={selectedValue=="Admin"?true:false} apiEndpoint={"_NAVIGATION_INFO-newversion-2-false&database=ContentManagement"} buttonText={"NEW VERSİON LGS DARK Mode"} isRedis={true}/></td>
                            </tr>
                            <tr>
                                <td>_NAVIGATION_PAGE_FLAGS_DUELLO1&database=ContentManagement(YKS)</td>
                                <td>duello için yanıp sönen top</td>
                                  <td><CacheButton notify={notify} isAdmin={selectedValue=="Admin"?true:false} apiEndpoint={"_NAVIGATION_PAGE_FLAGS_DUELLO1&database=ContentManagement"} buttonText={"YKS DUELLO FLAG"} isRedis={true}/></td>
                            </tr>
                            <tr>
                                <td>_NAVIGATION_PAGE_FLAGS_DUELLO2&database=ContentManagement(LGS)</td>
                                <td>duello için yanıp sönen top</td>
                                  <td><CacheButton notify={notify} isAdmin={selectedValue=="Admin"?true:false} apiEndpoint={"_NAVIGATION_PAGE_FLAGS_DUELLO2&database=ContentManagement"} buttonText={"LGS DUELLO FLAG"} isRedis={true}/></td>
                            </tr>
                            <tr>
                                <td>_NAVIGATION_PAGE_FLAGS_LOTTERY&database=ContentManagement</td>
                                <td>çekiliş için yanıp sönen top</td>
                                  <td><CacheButton notify={notify} isAdmin={selectedValue=="Admin"?true:false} apiEndpoint={"_NAVIGATION_PAGE_FLAGS_LOTTERY&database=ContentManagement"} buttonText={"LOTTERY FLAG"} isRedis={true}/></td>
                            </tr>
                            <tr>
                                <td>_NAVIGATION_PAGE_FLAGS_ONLINELECTURE-1&database=ContentManagement(YKS)</td>
                                <td>canlı dershane için yanıp sönen top</td>
                                  <td><CacheButton notify={notify} isAdmin={selectedValue=="Admin"?true:false} apiEndpoint={"_NAVIGATION_PAGE_FLAGS_ONLINELECTURE-1&database=ContentManagement"} buttonText={"YKS ONLİNE LECTURE FLAG"} isRedis={true}/></td>
                            </tr>
                            <tr>
                                <td>_NAVIGATION_PAGE_FLAGS_ONLINELECTURE-2&database=ContentManagement(LGS)</td>
                                <td>canlı dershane için yanıp sönen top</td>
                                  <td><CacheButton notify={notify} isAdmin={selectedValue=="Admin"?true:false} apiEndpoint={"_NAVIGATION_PAGE_FLAGS_ONLINELECTURE-2&database=ContentManagement"} buttonText={"LGS ONLİNE LECTURE FLAG"} isRedis={true}/></td>
                            </tr>
                            <tr>
                                <td>_NAVIGATION_INFO-oldversion-(ExamCategoryId)-(IsDarkModeEnable)</td>
                                <td>ContentManagement</td>
                                <td>Ana sayfada menülerin yerleri, görselleri ve yazıları</td>
                            </tr>
                            <tr>
                                <td>_NAVIGATION_PAGE_FLAGS_DUELLO(examCategoryId)</td>
                                <td>ContentManagement</td>
                                <td>duello için yanıp sönen top</td>
                            </tr>
                            <tr>
                                <td>_NAVIGATION_PAGE_FLAGS_LOTTERY</td>
                                <td>ContentManagement</td>
                                <td>çekiliş için yanıp sönen top</td>
                            </tr>
                            <tr>
                                <td>_NAVIGATION_PAGE_FLAGS_ONLINELECTURE-(examCategoryId)</td>
                                <td>ContentManagement</td>
                                <td>canlı dershane için yanıp sönen top</td>
                            </tr>
                            {/* Content Managment End */}
                            {/* --------------------------------- */}
                            {/* UserRanks Start */}
                            <tr><td colSpan="2" style={{ fontSize: 25 }}>UserRanks</td></tr>
                            <tr>
                                <td>CACHING_KEY_TOTAL_SOLVED_WEEKLY(examCategoryId)</td>
                                <td>UserRanks</td>
                                <td>haftalık çözülen soru sayısı</td>
                            </tr>
                            <tr>
                                <td>USER_RANKS_CORRECTSOLVED_(examCategoryId)(date)</td>
                                <td>UserRanks</td>
                                <td>kullanıcının doğru çözdüğü </td>
                            </tr>
                            <tr>
                                <td>USER_RANKS_CORRECTSOLVED_WEEKLY(examCategoryId)</td>
                                <td>UserRanks</td>
                                <td>kullanıcının doğru çözdüğü haftalık sorular</td>
                            </tr>
                            <tr>
                                <td>USER_RANKS_POINT_(examCategoryId)(date)</td>
                                <td>UserRanks</td>
                                <td>kullanıcı sıralama puanı</td>
                            </tr>
                            <tr>
                                <td>USER_RANKS_POINT_WEEKLY(examCategoryId)(date)</td>
                                <td>UserRanks</td>
                                <td>kullanıcının haftalık sıralama puanı</td>
                            </tr>
                            <tr>
                                <td>USER_RANKS_TOTALSOLVED_(examCategoryId)(date)</td>
                                <td>UserRanks</td>
                                <td>kullanıcının çözdüğü sorular</td>
                            </tr>
                            <tr>
                                <td>USER_RANKS_TOTALSOLVED_WEEKLY(examCategoryId)</td>
                                <td>UserRanks</td>
                                <td>kullanıcının haftalık çözdüğü sorular</td>
                            </tr>
                            {/* UserRanks End */}
                            {/* --------------------------------- */}
                            {/* Account Start */}
                            <tr><td colSpan="2" style={{ fontSize: 25 }}>Account</td></tr>
                            <tr>
                                <td>{`PHONE_CONFIRMATION_{userId}`}</td>
                                <td>Account</td>
                                <td>Empty</td>
                            </tr>
                            {/* Account End */}
                            {/* --------------------------------- */}
                            {/* EmailVerifications Start */}
                            <tr><td colSpan="2" style={{ fontSize: 25 }}>EmailVerifications</td></tr>
                            <tr>
                                <td>EMAIL_VERIFICATION_CODES</td>
                                <td>EmailVerifications</td>
                                <td>Empty</td>
                            </tr>
                            {/* EmailVerifications End */}
                            {/* --------------------------------- */}
                            {/* Redis Cache End */}
                        </tbody>
                    </table>
                </div>
            </div>
           
        </div>
    )
}

export default Caches