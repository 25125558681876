import React, { useState } from "react";
import useCorporateForm from "../hooks/useCorporateForm";
import { IconButton } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";

const ButtonWithCorporateModal = ({ forEdit, showEdit, initialValues, fields, onSubmit,buttonTitle,title }) => {
  const [isOpen, setIsOpen] = useState(false);
  console.log("initialValues ButtonWithCorporateModal",initialValues);
  const { formValues, handleChange, resetForm } = useCorporateForm(initialValues);

  const handleFormSubmit = () => {
    console.log("formValues",formValues);
    onSubmit(formValues);
    setIsOpen(false);
    resetForm();
  };

  return (
    <>
      {forEdit ? (
        showEdit ? (
          <IconButton onClick={() => setIsOpen(true)}>
            <EditIcon />
          </IconButton>
        ) : null
      ) : (
        <button onClick={() => setIsOpen(true)}>{buttonTitle}</button>
      )}
      {isOpen && (
        <div style={styles.modalBackdrop}>
          <div style={styles.modal}>
            <h2>{title}</h2>
            <form style={styles.form}>
              {fields.map((field) => (
                <label key={field} style={styles.label}>
                  {field.charAt(0).toUpperCase() + field.slice(1)}:
                  {typeof formValues[field] === "boolean" ? (
                    <select
                      name={field}
                      value={formValues[field]}
                      onChange={handleChange}
                      style={styles.select}
                    >
                      <option value={false}>False</option>
                      <option value={true}>True</option>
                    </select>
                  ) : (
                    <input
                      type="text"
                      name={field}
                      value={formValues[field] || ""}
                      onChange={handleChange}
                      style={styles.input}
                    />
                  )}
                </label>
              ))}
              <div style={styles.buttonContainer}>
                <button type="button" onClick={handleFormSubmit} style={styles.button}>
                  Send
                </button>
                <button type="button" onClick={() => setIsOpen(false)} style={styles.button}>
                  Close
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};
const styles = {
  modalBackdrop: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  modal: {
    backgroundColor: "white",
    padding: "20px",
    borderRadius: "8px",
    width: "400px",
    maxWidth: "90%",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  label: {
    display: "flex",
    flexDirection: "column",
    fontSize: "14px",
    color: "#333",
  },
  input: {
    padding: "8px",
    fontSize: "14px",
    borderRadius: "4px",
    border: "1px solid #ddd",
    marginTop: "5px",
  },
  select: {
    padding: "8px",
    fontSize: "14px",
    borderRadius: "4px",
    border: "1px solid #ddd",
    marginTop: "5px",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "20px",
  },
  button: {
    padding: "10px 20px",
    fontSize: "14px",
    borderRadius: "4px",
    border: "none",
    backgroundColor: "#007BFF",
    color: "white",
    cursor: "pointer",
  },
};
// Define styles (modalBackdrop, modal, form, label, etc.) here

export default ButtonWithCorporateModal;
