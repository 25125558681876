import { useState, useEffect } from "react";
import { updateCorporate } from "../../../api/corporate/corporate";
import useAuth from "../../../hooks/useAuth";
import { toast } from "react-toastify";
import ResponseStatus from "../../../util/enums";


const useCorporateModalUpdate = () => {
    const { user, userAdminToken } = useAuth();
    const [ userUpdatedCorporateInfo, setUserUpdatedCorporateInfo] = useState(null)
    const handleModalFormUpdate = async (formData) => {
        try {
            const res = await updateCorporate(formData,userAdminToken);
            if (res.data.ResponseStatus === ResponseStatus.Success) {
              toast.success("Corporate Başarıyle Oluşturuldu");
              setUserUpdatedCorporateInfo(res.data.ResponseData)
            } else {
              toast.error("Bir Hata Oluştu");
            }
          } catch (error) {
            toast.error("Bir Hata Oluştu");
          }
        
    }

    return  {handleModalFormUpdate,userUpdatedCorporateInfo,setUserUpdatedCorporateInfo}
}

export default useCorporateModalUpdate