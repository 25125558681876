import React, { useEffect } from "react";
import SideBar from "../../components/SideBar";
import { ToastContainer } from "react-toastify";
import { Components } from "../../assets/Dimensions";
import UserInfo from "./components/userInfo";
import useGetUserInfo from "./hooks/useGetUserInfo";
import UserInfoTable from "./components/userInfoTable";
import Buttons from "./components/buttons";
import useChangeRole from "./hooks/useChangeRole";
import useGetUserCorporate from "./hooks/useGetUserCorporate";
import ButtonWithCorporateModal from "./components/corporateModal";
import useModalSubmit from "./hooks/useModalSubmit";
import useCorporateModalUpdate from "./hooks/useCorporateModalUpdate";
import useGetUserSchoolInfo from "./hooks/useGetUserSchool";
import useAddSchoolModalSubmit from "./hooks/useAddSchoolModalSubmit";

const Corporate = () => {
  const { userInfoByMail, getUserInfo, userMail,newMailEntered } = useGetUserInfo();
  const { changeUserRole } = useChangeRole({ getUserInfo });
  const { userCorporateInfo, getUserCorporateInfo,setUserCorporateInfo } = useGetUserCorporate();
  const { handleModalFormSubmit, userCreatedCorporateInfo,isFromAddCorporate,setUserCreatedCorporateInfo } = useModalSubmit();
  const { handleAddSchoolModalFormSubmit, userCreatedSchoolInfo,isFromCreateSchool,setUserCorporateSchoolInfo } =
    useAddSchoolModalSubmit();
  const { handleModalFormUpdate, userUpdatedCorporateInfo } =
    useCorporateModalUpdate();
  const { userSchoolInfo, getSchool,setUserSchoolInfo } = useGetUserSchoolInfo();


  const styles = {
    container: {
      marginLeft: Components.SideBarWidth + 15,
      paddingTop: 20,
      marginRight: 30,
    },
    rowActionIcon: {
      width: Components.Image.Big.Width,
      height: Components.Image.Big.Height,
    },
    ChangeDateModalStyle: {
      padding: 20,
      margin: "auto",
    },
    iconButton: {
      right: 0,
      top: 0,
    },
    section: {
      marginTop: "3%",
    },
    innerSection: {
        display:"flex",
        flexDirection:"column",
      gap: 10,
    },
  };

  useEffect(()=>{
    setUserCorporateInfo(null)
    setUserCreatedCorporateInfo(null)
    setUserCorporateSchoolInfo(null)
    setUserSchoolInfo(null)
  },[userInfoByMail])


  const userInformationForModal = {
    // name: userInfoByMail?.Name || "",
    UserId: userInfoByMail ? userInfoByMail.UserId : "",
    // promotionName: userCorporateInfo?.PromotionName || "",
    // hasLottery: userCorporateInfo?.HasLottery || false,
    // rowStatus: userCorporateInfo?.RowStatus || false,
  };
  console.log("initialValuesForModal",userCorporateInfo ? userCorporateInfo.Id : "",);

  const initialValuesForModal = {
    Id:userCorporateInfo ? userCorporateInfo.Id : "",
    Name: userCorporateInfo ? userCorporateInfo.Name : "",
    UserId: userCorporateInfo ? userCorporateInfo.UserId : "",
    PromotionName: userCorporateInfo ? userCorporateInfo.PromotionName : "",
    HasLottery: userCorporateInfo ? userCorporateInfo.HasLottery : false,
    RowStatus: userCorporateInfo ? userCorporateInfo.RowStatus : false,
    // corporateSchools: userCorporateInfo
    //   ? userCorporateInfo.CorporateSchools
    //   : null,
    // corporateLotteries: userCorporateInfo
    //   ? userCorporateInfo.CorporateLotteries
    //   : null,
    // corporatePromotionNames: userCorporateInfo
    //   ? userCorporateInfo.CorporatePromotionNames
    //   : null,
  };
console.log("initialValuesForModal",initialValuesForModal,userCorporateInfo);


  return (
    <div>
      <SideBar />
      <ToastContainer />
      <div style={styles.container}>
        <div style={styles.innerSection}>
          <UserInfo getUserInfo={getUserInfo} />
          <UserInfoTable
            nullMessage={"No userInfo available"}
            userInfo={userInfoByMail}
          />
        </div>
        {userInfoByMail && (
          <div style={styles.section}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{display:"flex", gap:3}}>
                <Buttons
                  onClick={() => changeUserRole(userInfoByMail, userMail)}
                  name="Assign Role"
                />
                <Buttons
                  onClick={() => getUserCorporateInfo(userInfoByMail)}
                  name="Get Corporate"
                />
              </div>
              <ButtonWithCorporateModal
                fields={[
                  "Name",
                  "UserId",
                  "PromotionName",
                  // "hasLottery",
                  // "rowStatus",
                ]}
                initialValues={userInformationForModal}
                onSubmit={handleModalFormSubmit}
                buttonTitle={"Add Corporate"}
                title={"Corporate Info"}
              />
            </div>
            {console.log("userCreatedCorporateInfo",userCreatedCorporateInfo)}
            {(userCreatedCorporateInfo ||userCorporateInfo )&& (
                <div 
                key={userMail}
                style={styles.section}>
                  <div style={{ display: "flex", flexDirection: "row", gap:3}}>
                    <UserInfoTable
                      nullMessage={"kullanıcı Kurumsal Bilgisi Bulunmamaktadır"}
                      userInfo={
                        isFromAddCorporate?userCreatedCorporateInfo : userCorporateInfo
                      }
                    />
                    <Buttons
                      onClick={() => getSchool(isFromAddCorporate?userCreatedCorporateInfo.Id:userCorporateInfo.Id)}
                      name="Get School"
                    />
                    <ButtonWithCorporateModal
                      fields={[
                        "Id",
                        "Name",
                        "UserId",
                        "PromotionName",
                        "HasLottery",
                        "RowStatus",
                      ]}
                      forEdit
                      showEdit={userCreatedCorporateInfo ||userCorporateInfo }
                      initialValues={initialValuesForModal}
                      onSubmit={handleModalFormUpdate}
                      title={"Update Corporate Info"}
                    />
                  </div>
                  {
                    <div style={{ display: "flex", flexDirection: "row", gap:3, marginTop:"3%" }}>
                      {console.log("userCreatedSchoolInfo",userCreatedSchoolInfo)}
                      {(userCreatedSchoolInfo || userSchoolInfo) && (
                        <UserInfoTable
                            userInfo={isFromCreateSchool ? userCreatedSchoolInfo : userSchoolInfo}
                            nullMessage="Kullanıcın okul bilgisi bulunmamaktadır"
                          />
                        )}

                      <ButtonWithCorporateModal
                        initialValues={userInformationForModal}
                        fields={["Name", "UserId"]}
                        onSubmit={handleAddSchoolModalFormSubmit}
                        buttonTitle={"Add School"}
                        title={"School Info"}
                      />
                    </div>
                  }
                </div>
              )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Corporate;
