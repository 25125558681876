import { ApiUrl, ApiUrlAdmin } from "../common/Config";
import { postAdmin, post, get, getAdmin, postFormData,put } from "../common/Helper";

export const getUserInfoByEmail = async (token, email) => {
  let url = ApiUrlAdmin + `account/${email}`;
  return await getAdmin(url, token);
};

export const roleUpdate = async (data, token) => {
  let url = ApiUrlAdmin + `user/updateRole?userId=${data}`;
  return await getAdmin(url, token);
};

export const getCorporateInfo = async (token, userId) => {
  let url = ApiUrlAdmin + `schoolpanel/getcorporate?userId=${userId}`;
  return await getAdmin(url, token);
};

export const addCorporate = async (data, token) => {
  let url = ApiUrlAdmin + `schoolpanel/addcorporate`;
  return await post(url, data, token);
};

export const updateCorporate = async (data, token) => {
  let url = ApiUrlAdmin + `schoolpanel/updatecorporate`;
  return await post(url, data, token);
};

export const getUserCorporateSchool = async (corporateId,token) => {
    let url = ApiUrlAdmin + `schoolpanel/getschool/${corporateId}`;
    return await getAdmin(url,token)
}

export const addCorporateSchool = async (data, token) => {
    let url = ApiUrlAdmin + `schoolpanel/addschool`;
    return await post(url, data, token);
  };