import { useState } from "react"

const useHandleOnChangeEmail = () => {
    const [email, setEmail] = useState("")
    const handleOnChange = (e) => {
        const { value } = e.target
        setEmail(value)
    }
    return {email,handleOnChange}
}

export default useHandleOnChangeEmail