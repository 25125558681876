import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

//#region Material Components
import { withStyles } from '@material-ui/core';
import MuiDrawer from '@material-ui/core/Drawer';
import MuiButton from '@material-ui/core/Button';
import MuiMenu from '@material-ui/core/Menu';
import MuiMenuItem from '@material-ui/core/MenuItem';
import MuiList from '@material-ui/core/List';
import MuiListItem from '@material-ui/core/ListItem';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
import MuiListItemText from '@material-ui/core/ListItemText';
import MuiDivider from '@material-ui/core/Divider';
import MuiTypography from '@material-ui/core/Typography';
//#endregion

//#region Internal
import { Primary } from '../assets/Colors';
import { Components, FontSize } from '../assets/Dimensions';
import { Text } from '../assets/Strings';

// import AvatarIcon from './../assets/images/avatar.png';
import MembersIcon from './../assets/images/members.png';
import BooksIcon from './../assets/images/books.png';

import { logoutUser } from './../redux/actions/userAction';
//#endregion

//#region Override Style
const Typography = withStyles({
    root: {
        color: 'rgba(255, 255, 255, 0.58)',
        fontSize: FontSize.VerySmall,
        textTransform: 'capitalize',
        padding: 0
    }
})(MuiTypography);

const Drawer = withStyles({
    paper: {
        backgroundColor: Primary.SideBar,
        width: Components.SideBarWidth
    }
})(MuiDrawer);

const ProfileButton = withStyles({
    root: {
        maxWidth: 175,
        padding: 0
    },
    label: {
        color: Primary.ContainerBackground,
        fontSize: FontSize.VerySmall,
        textTransform: 'capitalize'
    }
})(MuiButton);

const Menu = withStyles({
    paper:{
        backgroundColor: Primary.ProfileTemplateBackground,
        color: Primary.ContainerBackground,
        top: '50px !important',
        opacity: '0.9 !important',
        left: '80px !important'
    }
})(MuiMenu);

const MenuItem = withStyles({
    root: {
        fontSize: FontSize.Small
    }
})(MuiMenuItem);

const ListItemText = withStyles({
    root: {
        color: Primary.ContainerBackground
    },
    primary: {
        fontSize: FontSize.Mid
    }
})(MuiListItemText);

const List = withStyles({
    root: {
        marginTop: 10
    }
})(MuiList);

const Divider = withStyles({
    root: {
        backgroundColor: Primary.SideBarText,
        margin: '4px 15px 4px 15px',
        height: 1
    }
})(MuiDivider);

const ListItem = withStyles({
    root: {
        transition: 'background 0.7s',
        '&:hover': {
            backgroundColor: 'rgb(255,61,0, 0.3)',
            cursor: 'pointer'
        },
        '&.Mui-selected':{
            backgroundColor: 'rgb(255,61,0, 0.6)',
            borderTopRightRadius: 20,
            borderBottomRightRadius: 20,
            '&:hover': {
                backgroundColor: 'rgb(255,61,0, 0.3)'
            }
        }
    }
})(MuiListItem);
//#endregion

//#region Inline Style
const styles = {
    avatarIcon: {
        float: 'left'
    },
    profileTemplate: {
        backgroundColor: Primary.ProfileTemplateBackground,
        padding: '18px 10px'
    }
}
//#endregion

class SideBar extends Component {
    constructor(props){
        super(props);

        this._user = JSON.parse(localStorage.getItem('user'));

        this.state={
            profileMenuIsOpen: false,
            anchorEl: null
        };
    }

    componentWillUpdate(){
        this._user= JSON.parse(localStorage.getItem('user'));
        if(this._user === null){
            this.props.history.push('./login');
        }
    };

    _profileMenuClose = () => {
        this.setState({
            profileMenuIsOpen: false
        });
    };

    _handleUserProfile = () => {
        this.setState({
            profileMenuIsOpen: false
        });
    };

    _logout = () => {
        localStorage.clear();
        this.props.logOutUser();
        this.props.history.push('/login');
    };

    _getMenuList = () => {
        let componentList = [];
        if(this._user !== null && ((this._user.Role === 'OnlineLectureContentCreator')^ (this._user.Role ==="Administrators"))){
            let onlineLecturePanel = {
                href: '/onlineLecturePanel',
                text: "Canlı Ders Paneli",
                icon: BooksIcon,
            }
            let contentManagment = {
                href: '/contentManagment',
                text: "Content Managment",
                icon: MembersIcon,
            }
            componentList.push(onlineLecturePanel);
            componentList.push(contentManagment);
        }
        if(this._user !== null && ((this._user.Role === 'TeacherEditors')^ (this._user.Role ==="Administrators"))){
            let questionVideoSolution = {
                href: '/questionVideoSolution',
                text: "Soru Video Çözüm Paneli",
                icon: MembersIcon,
            };
            let subtopicSelectionCourses = {
                href: '/questionSubTopicSelectionCoursePage',
                text: "Sorulara konu ekleme",
                icon: MembersIcon,
            };
            let AchievementCoursePage = {
                href: '/AchievementCourses',
                text: "Sorulara Kazanım ekleme",
                icon: MembersIcon,
            };
            let KpssCompatibility = {
                href: '/KpssCompatibilityCourses',
                text: "KPSS Soru Eşleştirme",
                icon: MembersIcon,
            }

            componentList.push(questionVideoSolution);
            componentList.push(subtopicSelectionCourses);
            componentList.push(AchievementCoursePage);
            componentList.push(KpssCompatibility);
        }
        if(this._user !== null && this._user.Role !== 'OnlineLectureContentCreator' && this._user.Role !== 'TeacherEditors'){
            let workList = {
                href: '/worklist',
                text: Text.WorkList,
                icon: MembersIcon,
            };
            componentList.push(workList);
           
        }
        
        if(this._user !== null && this._user.Role === 'Administrators'){
            let members = {
                href: '/members',
                text: Text.Members,
                icon: MembersIcon
            };
            let books = {
                href: '/books',
                text: Text.Books,
                icon: BooksIcon,
            };
            let feedBacks = {
                href: '/feedbacks',
                text: "FeedBacks",
                icon: MembersIcon,
            };
            let UserCredits = {
                href: '/usercredits',
                text: "Credits",
                icon: MembersIcon,
            };
            let SystemCaches = {
                href: '/caches',
                text: "Cache",
                icon: MembersIcon,
            };
            let Corporate = {
                href:'/Corporate',
                text: 'Corporate',
                icon: MembersIcon
            }
            componentList.push(members);
            componentList.push(feedBacks);
            componentList.push(books);
            componentList.push(UserCredits);
            componentList.push(SystemCaches);
            componentList.push(Corporate);
        }

        return componentList;
    };

    _profileMenuOpen = (e) => {
        this.setState({
            profileMenuIsOpen: true,
            anchorEl: e.currentTarget
        });
    };

    render(){
        const menuList = this._getMenuList();

        return(
            <div>
                <Drawer variant='permanent'>
                    <div style={{...styles.profileTemplate}}>
                        {/* <img
                            alt='avatar'
                            src={AvatarIcon}
                            style={{...styles.avatarIcon}}
                        /> */}

                        <ProfileButton aria-controls='simple-menu' aria-haspopup='true' onClick={this._profileMenuOpen}>
                            {this._user !== null ? this._user.UserName : '' }
                        </ProfileButton>
                        <Typography>
                            {this._user !== null ? this._user.Role : ''}
                        </Typography>

                        <Menu
                            anchorEl={this.state.anchorEl}
                            keepMounted
                            open={this.state.profileMenuIsOpen}
                            onClose={this._profileMenuClose}
                        >
                            <MenuItem onClick={this._handleUserProfile} component={Link} to='./userprofile'>{Text.UserProfile}</MenuItem>
                            <MenuItem onClick={this._logout} component={Link} to='./login'>{Text.Logout}</MenuItem>
                        </Menu>
                    </div>

                    <div style={{...styles.menuList}}>
                        <List component="nav">
                            {menuList.map((item) => (
                                <div key={item.text + '_div'} style={{marginRight: 10}}>
                                    <ListItem button component={Link} to={item.href} selected={item.text === this.props.SelectedItem}>
                                        {/* <ListItemIcon>
                                            <img
                                                alt='icon'
                                                src={item.icon}
                                            />
                                        </ListItemIcon> */}
                                        <ListItemText primary={item.text} />
                                    </ListItem>
                                    <Divider/>     
                                </div>
                            ))}    
                        </List>
                    </div>
                </Drawer>
            </div>
        );
    }
}

SideBar.propTypes = {
    User: PropTypes.object
};

SideBar.defaultProps = {
};

const mapStateToProps = (state) => ({
    User: state.userReducer.User,
    SelectedItem: state.sidebarReducer.SelectedItem
});

const mapDispatchToProps = (dispatch) => ({
    logOutUser: () => dispatch(logoutUser())
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SideBar));