import React from "react";
import useHandleOnChangeEmail from "../hooks/useHandleOnChangeEmail";
import Buttons from "./buttons";
const UserInfo = ({ getUserInfo }) => {
  const { handleOnChange, email } = useHandleOnChangeEmail();
  const styles = {
    container: {
      display: "flex",
      flexDirection: "row",
      gap: 12,
    },
    input: {
      padding: 12,
    },
    button: {
      padding: 12,
    },
  };

  return (
    <div style={styles.container}>
      <input
        style={styles.input}
        type="text"
        placeholder="Email Adress"
        value={email}
        onChange={handleOnChange}
      />
      <Buttons onClick={() => getUserInfo(email)} name={"Get User Info"}/>
    </div>
  );
};

export default UserInfo;
