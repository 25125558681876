// hooks/useAuth.js
import { useEffect, useState } from 'react';

const useAuth = () => {
  const [user, setUser] = useState(null);
  const [userAdminToken, setUserAdminToken] = useState(null);

  useEffect(() => {
    // Retrieve the user and token from storage when the hook is used
    const storedUser = JSON.parse(localStorage.getItem("user"));
    const storedToken = sessionStorage.getItem("jwtP");
    setUser(storedUser);
    setUserAdminToken(storedToken);
  }, []);

  return { user, userAdminToken };
}

export default useAuth