import { useState } from "react";
import { getUserInfoByEmail } from "../../../api/corporate/corporate";
import { isValidEmail } from "../../../helpers/emailValidator";
import { toast } from "react-toastify";
import ResponseStatus from "../../../util/enums";
import useAuth from "../../../hooks/useAuth";
import useGetUserCorporate from "./useGetUserCorporate";
import useGetUserSchoolInfo from "./useGetUserSchool";
import useModalSubmit from "./useModalSubmit";
import useAddSchoolModalSubmit from "./useAddSchoolModalSubmit";

const useGetUserInfo = () => {
    const { user, userAdminToken } = useAuth()
    const [userMail,setUserMail] = useState("")
    const [userInfoByMail, setUserInfoByMail] = useState(null);
    const {setUserCorporateInfo} =useGetUserCorporate();
    const {setUserSchoolInfo} = useGetUserSchoolInfo()
    const {setUserCreatedCorporateInfo} = useModalSubmit()
    const {setUserCorporateSchoolInfo} = useAddSchoolModalSubmit()

  const getUserInfo = async (email) => {
    if (isValidEmail(email)) {
      const userInfoBymail = await getUserInfoByEmail(userAdminToken, email);
      if (userInfoBymail.status == 200) {

      if (userInfoBymail.data.ResponseStatus === ResponseStatus.Success) {
        setUserInfoByMail(userInfoBymail.data.ResponseData);
        setUserMail(email)
      }else if(userInfoBymail.data.ResponseStatus === ResponseStatus.Error){
        toast.error(userInfoBymail.data.ResponseMessage)
        setUserInfoByMail(userInfoBymail.data.ResponseData)
      }else{
        toast.error("Bir Hata Oluştu")
      }
    }else{
      toast.error("Bir Hata Oluştu")
    }
    } else {
      toast.error("Geçerli bir email adresi giriniz.");
    }
};
return { userInfoByMail,getUserInfo,userMail };
};

export default useGetUserInfo;
